import React, { Component, } from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faAdd, faBriefcase, faMobileScreen, faPerson, faRightFromBracket, faSearch, faUtensils, faFilter, faClose, faComments, faMessage } from '@fortawesome/free-solid-svg-icons';
import config from "../utils/config";

//
import '../css/components/MenuBar.css';


//
import CreatorData from '../data/creatorData';
import MenuDropdown from './MenuDropdown';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../components/ui/dialog"
import PreferencesForm from '../pages/consumer/form.js'

//
import SmartRecipeService from '../services/smartrecipe.js';
import { ChevronRight, X, ChevronLeft } from 'lucide-react';
import clsx from 'clsx';


const cuisineOptions = [
    { id: 'mexican', label: 'Mexican' },
    { id: 'german', label: 'German' },
    { id: 'chinese', label: 'Chinese' },
    { id: 'american', label: 'American' },
    { id: 'italian', label: 'Italian' },
    { id: 'japanese', label: 'Japanese' },
    { id: 'spanish', label: 'Spanish' },
    { id: 'korean', label: 'Korean' },
    { id: 'moroccan', label: 'Moroccan' },

];

const ingredientOptions = [
    { id: 'beef', label: 'Beef' },
    { id: 'pork', label: 'Pork' },
    { id: 'chicken', label: 'Chicken' },
    { id: 'tofu', label: 'Tofu' },
    { id: 'eggs', label: 'Eggs' },
    { id: 'seafood', label: 'Seafood' },
    { id: 'vegetables', label: 'Vegetables' },
    { id: 'pasta', label: 'Pasta' },
];

const mealOptions = [
    { id: "quick", label: "30 Minute Meals" },
    { id: 'breakfast', label: 'Breakfast/Brunch' },
    { id: 'lunch', label: 'Lunch' },
    { id: 'dinner', label: 'Dinner' },
    { id: 'desserts', label: 'Desserts' },
    { id: 'healthy', label: 'Healthy' },
];

const dietOptions = [
    { id: 'vegan', label: 'Vegan' },
    { id: 'vegetarian', label: 'Vegetarian' },
    { id: 'keto', label: 'Keto' },
    { id: 'paleo', label: 'Paleo' },
    { id: 'dairy free', label: 'Dairy-Free' },
    { id: 'gluten free', label: 'Gluten-Free' },
    { id: 'kosher', label: 'Kosher' }
];

//

class MenuBar extends Component {

    menuData;

    constructor(props) {

        console.log('menubar > ctor');

        super(props);

        this.toggleMenu = this.toggleMenu.bind(this);
        this.userMenuRef = React.createRef();
        this.searchBarRef = React.createRef();
        this.searchMenuRef = React.createRef();
        this.customMenuBtnRef = React.createRef();
        this.mobileSearchBtnRef = React.createRef();

        this.menuData = {
            creator: [
                {
                    type: 'link',
                    name: 'Dashboard',
                    url: '/creator/dashboard',

                },
                {
                    type: 'link',
                    name: 'Your Recipes',
                    url: '/creator/recipes',
                },
                {
                    type: 'link',
                    name: 'Marketplace',
                    url: '/creator/marketplace',
                },
                {
                    type: 'link',
                    name: 'Sponsorships',
                    url: '/creator/sponsorships',
                },
            ],
            brand: [
                {
                    type: 'link',
                    name: 'Dashboard',
                    url: '/brand/dashboard',
                },
                {
                    type: 'divider',
                },
                {
                    type: 'link',
                    name: 'Recipes',
                    url: '/brand/recipes',
                },
                {
                    type: 'divider',
                },
                {
                    type: 'link',
                    name: 'Manage Appliances',
                    url: '/brand/appliances',
                },
                {
                    type: 'divider',
                },
                {
                    type: 'link',
                    name: 'Creators',
                    url: '/brand/marketplace',
                },
                {
                    type: 'divider',
                },
                {
                    type: 'link',
                    name: 'Sponsorships',
                    url: '/brand/sponsorships',
                },
                // {
                //     type: 'divider',
                // },
            ],
            consumer: [
                {
                    type: 'link',
                    name: 'All Recipes',
                    url: '/consumer/recipes',
                },
                {
                    type: 'divider',
                },
                {
                    type: 'dropdown',
                    id: 'cuisines',
                    name: 'Cuisines',
                    items: cuisineOptions,
                },
                {
                    type: 'dropdown',
                    id: 'diets',
                    name: 'Diets',
                    items: dietOptions,
                },
                {
                    type: 'dropdown',
                    id: 'meals',
                    name: 'Meals',
                    items: mealOptions,
                },
                {
                    type: 'dropdown',
                    id: 'ingredients',
                    name: 'Ingredients',
                    items: ingredientOptions,
                },
                {
                    type: 'divider',
                },
                {
                    type: 'link',
                    icon: faSearch,
                    name: 'Search',
                    onClickHandler: () => this.handleSearchOnClick(),
                    ref: this.searchMenuRef,
                },
                {
                    type: 'divider',
                },
                {
                    type: 'filter',
                    icon: faFilter,
                    name: 'Preferences',
                },
            ],
        };


        this.state = {
            userMenuVisible: false,
            userMenuItems: this.getMenuItems(props.currentUserType),
            mobileMenu: this.getMobileMenuItems(props.currentUserType),
            isMobileMenuVisible: false,
            searchBarVisible: false,
            showError: false,
            preferences: 0,
            openDialog: false,
            loading: false,
            searchQuery: '',
            isMobile: false,
            isFeedbackFormVisible: false
        };


    }
    componentDidMount() {
        if(window.innerWidth < 1024) {
            this.setState({
                isMobile: true
            })
        }
        document.addEventListener("mousedown", this.handleClickOutside)
    }
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside)
    }

    handleClickOutside = (event) => {
        if (this.state.userMenuVisible === true && this.userMenuRef && !this.userMenuRef.current.contains(event.target)) {
            this.setState({ userMenuVisible: false });
        }
        if (
            this.state.searchBarVisible === true &&
            this.searchBarRef &&
            !this.searchBarRef.current.contains(event.target) &&
            this.mobileSearchBtnRef &&
            !this.mobileSearchBtnRef.current.contains(event.target) &&
            this.searchMenuRef &&
            !this.searchMenuRef.current.contains(event.target)
          ) {
            const addMarginTopAndAnimationClass = document.getElementById("mainId");
            this.setState(
              {
                searchBarVisible: false,
              },
              () => {
                if (this.state.isMobile) {
                  addMarginTopAndAnimationClass.style.marginTop = "5rem";
                } else {
                  addMarginTopAndAnimationClass.style.marginTop = "7rem";
                }
              }
            );
        }  
    }

    handleMenuOptionClick = (menuId, optionId) => {
        //console.log('handleMenuOptionClick: ', menuId, optionId);
        this.setState({ dropdownMenuVisible: false, searchBarVisible: false });
        if (menuId === 'cuisines') this.props.navigate(`../${this.props.currentUserType}/recipes/cuisines/${optionId}`);
        if (menuId === 'diets') this.props.navigate(`../${this.props.currentUserType}/recipes/diets/${optionId}`);
        if (menuId === 'meals') this.props.navigate(`../${this.props.currentUserType}/recipes/meals/${optionId}`);
        if (menuId === 'ingredients') this.props.navigate(`../${this.props.currentUserType}/recipes/ingredients/${optionId}`);
    }

    //

    handleManageProfile = (userType) => {
        this.setState({ userMenuVisible: false });
        this.props.navigate('../' + userType + '/profile/edit')
    }

    handleManageDevices = (userType) => {
        this.setState({ userMenuVisible: false });
        this.props.navigate('../' + userType + '/devices/manage');
    }

    handleLogoutClick = () => {
        this.setState({ userMenuVisible: false });
        this.props.logoutFunc();
        this.props.navigate('../');
    }

    handleUserMenuButtonClick = () => {
        //console.log({msg:'handleUserMenuButtonClick', 'userMenuVisible':this.state.userMenuVisible});
        this.setState({ userMenuVisible: !this.state.userMenuVisible });
    };

    handleUserMenuOptionClick = (userType) => {
        //console.log({msg:'handleUserMenuOptionClick', 'userMenuVisible':this.state.userMenuVisible});
        this.setState({ userMenuVisible: false, userMenuItems: this.getMenuItems(userType) });
        this.props.setUserTypeFunc(userType);
        if (userType === 'consumer') {
            this.props.navigate('../' + userType + '/recipes');
        } else {
            this.props.navigate('../' + userType + '/dashboard');
        }
    };

    handleMenuClick = () => {
        //console.log('handleMenuClick');
        this.setState({ searchBarVisible: false });
    }

    handleSearchOnClick = () => {
        console.log({ msg: 'search', 'state': this.state.searchBarVisible }, "sdaijgadi");
        const addMarginTopAndAnimationClass = document.getElementById('mainId');
        this.setState(
            {
                searchBarVisible: !this.state.searchBarVisible,
                isMobileMenuVisible: false
            },
            () => {
                if (this.state.searchBarVisible) {
                    if(this.state.isMobile) {
                        addMarginTopAndAnimationClass.style.marginTop = '11rem';
                    } else {
                        addMarginTopAndAnimationClass.style.marginTop = '20rem';
                    }
                } else {
                    if(this.state.isMobile) {
                        addMarginTopAndAnimationClass.style.marginTop = '5rem'
                    } else {
                        addMarginTopAndAnimationClass.style.marginTop = '7rem'
                    }
                }
            }
        );
    }

    handleFeedbackButtonClick = () => {
        this.setState({
            isFeedbackFormVisible: !this.state.isFeedbackFormVisible
        })
    }

    handleSearchEntry = (e) => {
        //this.setState({searchQuery:e.target.value});
        //this.props.navigate(`../${this.props.currentUserType}/recipes?search=${e.target.value}`);
    }

    handleSearchOnSubmit = async (e) => {
        const addMarginTopAndAnimation = document.getElementById('mainId');
        if(this.state.isMobile) {
            addMarginTopAndAnimation.style.marginTop = '5rem';
        } else {
            addMarginTopAndAnimation.style.marginTop = '7rem';
        }
        let query;
        if(typeof(e) == 'object') {
         e.preventDefault(); // prevent the default form submission behavior
          query = e.target.value;
        } else {
            query = `${e}`;
        }
        const checkIfqueryisUrl = this.isUrlValid(query)
        console.log(checkIfqueryisUrl)
        if (checkIfqueryisUrl == true) {
            const checkifUrlisfromSupportedWebsites = this.isValidWebsite(query);
            if (checkifUrlisfromSupportedWebsites == true) {
                this.setState({
                    loading: true
                })
                console.log('not here')
                const responses = await fetch(`${config.AI_BASE_URL}/backend/scrap_recipe`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        "key": config.AI_API_KEY,
                        "recipe_url": query
                    })
                })
                const final = await responses.json()
                console.log(final, "final is ")
                if (final.status == 200) {
                    if (Object.keys(final.recipe_data[1]).length == 0) {
                        this.showError()
                    }

                    setTimeout(async () => {
                        const getRecipeId = await SmartRecipeService.getNewRecipeid(final.recipe_data.recipe_id);

                        if (getRecipeId.copied_recipe_id != undefined) {
                            window.location.href = `/consumer/recipes/${getRecipeId.copied_recipe_id}/view`
                        } else {
                            window.location.href = `/consumer/recipes`
                        }

                    }, 3000)

                } else {
                    this.showError();
                }
                return
            } else {
                console.log(' here')

                this.setState({
                    showError: true
                })
                setTimeout(() => {
                    this.setState({
                        showError: false
                    })
                }, 2500);
                return
            }
        } else {
            const url = query ? `../${this.props.currentUserType}/recipes/search/${query}` : `../${this.props.currentUserType}/recipes`;
            this.props.navigate(url);
            //this.setState({ searchBarVisible: false, searchQuery: query });
            this.setState({ searchBarVisible: false, searchQuery: undefined });
        }
    }
    showError() {
        this.setState({
            loading: false,
            showError: true
        })
        setTimeout(() => {
            this.setState({
                showError: false
            })
        }, 2500);
        return
    }
    isUrlValid(userInput) {
        var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        if (res == null)
            return false;
        else
            return true;
    }

    toggleMenu() {
        this.setState({
            searchBarVisible: false
        });
        this.setState({
            isMobileMenuVisible: !this.state.isMobileMenuVisible
        });
        // var menuContainer = document.querySelector('.mobile-custom-menu');
        // menuContainer.classList.toggle('-translate-x-full');
    }

    toggleSubMenu(index) {
        var subMenu = document.getElementById('sub-menu-' + index);
        subMenu.classList.toggle('translate-x-full');
    }



    //

    getMenuItems(currentUserType) {

        console.log({ msg: 'getMenuItems', 'menus': this.menuData });

        const curPath = this.props.location.pathname;
        const menuEntries = Object.assign([], this.menuData[currentUserType]);

        // config toggle to hide/show Add Recipe link
        // if (currentUserType === 'consumer' && config.ENABLE_ADD_RECIPE) {
        //     menuEntries.push(
        //         {
        //             type: 'divider',
        //         },
        //         {
        //             type: 'link',
        //             name: 'Add a recipe',
        //             icon: faAdd,
        //             url: '/consumer/recipes/create',
        //             onClickHandler: () => this.handleMenuClick(),
        //         }
        //     );
        // }

        return menuEntries.map((item, index) => {
            switch (item.type) {
                case 'link':
                    const linkClass = (currentUserType !== 'consumer' && curPath.indexOf(item.url) >= 0) ? 'selected' : ''
                    const itemIconHtml = item.icon ? <FontAwesomeIcon className='menuIcon text-white' icon={item.icon} /> : <></>;
                    const itemNameHtml = item.name === "Search" ? <span className='lg:hidden xl:block'>{item.name}</span> : item.name;
                    return <Link key={`menu-item-${index}`} to={item.url ? item.url : null} ref={item.ref ? item.ref : null} onClick={item.onClickHandler ? item.onClickHandler : null} className={`flex items-center text-white text-center ${linkClass}`}>{itemIconHtml}{itemNameHtml}</Link>
                case 'divider':
                    return <span key={`menu-item-${index}`} className='menuDivider hidden lg:block'></span>
                case 'filter':
                    const linkClass2 = (currentUserType !== 'consumer' && curPath.indexOf(item.url) >= 0) ? 'selected' : undefined
                    const itemIconHtML2 = item.icon ? <img style={{
                        height: '20px',
                        display: 'inline',
                        marginRight: '6px',
                    }} src='/filters.png' /> : <></>;
                    return <div>
                        <Dialog className="min-w-[50vw]" id="dialog-trigger" style={{
                            background: "#f5f5f5",
                            overflowY: "scroll !important"
                        }}>
                            <DialogTrigger>
                                <div onClick={() => {
                                    this.setState({
                                        searchBarVisible: false
                                    })

                                }} className='bg-gray-200 px-[13px] py-[6px] rounded-[4px] flex flex-column justify-center cursor-pointer preferences-menu-btn'>

                                    <img src='/filters.png' alt="" style={{
                                        display: 'inline-block',
                                        height: "15px",
                                        marginRight: '5px',
                                        marginTop: '3.8px'

                                    }} />{item.name}
                                </div>

                            </DialogTrigger>
                            <DialogContent className="w-[90%] min-w-[60vw] lg:max-w-screen-lg overflow-y-scroll h-[65%] md:h-[85vh] max-h-screen" style={{
                                background: "#f5f5f5",
                                overflowY: "scroll !important",
                                // height: '85vh',
                                marginTop: "35px",
                                zIndex: 999
                            }}>
                                <DialogHeader>
                                    {/* <DialogTitle></DialogTitle> */}
                                    <DialogDescription className='mt-4' >
                                        <PreferencesForm onSubmit={() => this.LoginFunc()} />
                                    </DialogDescription>
                                </DialogHeader>
                            </DialogContent>
                        </Dialog>
                    </div>
                case 'dropdown':
                        return <MenuDropdown key={`menu-item-${index}`} id={item.id} items={item.items} onClick={this.handleMenuClick} handleOptionClick={this.handleMenuOptionClick}>{item.name}</MenuDropdown>
                default:
                    return <></>;
            }
        });

    }

    getMobileMenuItems(currentUserType) {
        const curPath = this.props.location.pathname;
        const menuEntries = Object.assign([], this.menuData[currentUserType]);
        return menuEntries.map((item, index) => {
            if(item.type !== "divider") {
                switch (item.type) {
                    case 'link':
                        if(item.name !== "Search") {
                            const linkClass = (currentUserType !== 'consumer' && curPath.indexOf(item.url) >= 0) ? 'selected' : undefined
                            const itemIconHtml = item.icon ? <FontAwesomeIcon className='mr-2' icon={item.icon} /> : <></>;
                            const itemNameHtml = item.name === "Search" ? <span className='lg:hidden xl:block'>{item.name}</span> : item.name;
                            return <>
                                <div className="flex items-center justify-center gap-4">
                                    <div id='manage-devices' className="final-click flex flex-col gap-1 items-center text-xl" onClick={() => this.handleManageDevices(currentUserType)}><FontAwesomeIcon icon={faUtensils} className="text-yellow-500" style={{ width: "24px", marginRight: "8px" }} /><span className="text-sm">My Kitchen</span></div>
                                    <div className="final-click flex flex-col gap-1 items-center p-4 text-xl" onClick={this.handleFeedbackButtonClick}>
                                        <FontAwesomeIcon className='mr-2 text-yellow-500' icon={faMessage} />
                                        <span className="text-sm">Feedback</span>
                                    </div>
                                </div>
                                <Link key={`menu-item-${index}`} to={item.url ? item.url : null} ref={item.ref ? item.ref : null} onClick={item.onClickHandler ? item.onClickHandler : null} className={`final-click flex items-center p-4 text-lg ${linkClass}`}>{itemIconHtml}{itemNameHtml}</Link>
                                </>
                        }
                        break;
                    case 'filter':
                        return <div className="p-4" key={index}>
                            <div id='logout' onClick={() => this.handleLogoutClick()} className="flex items-center justify-between text-lg">Logout<ChevronRight/></div>
                            <Dialog className="min-w-[50vw]" id="dialog-trigger" style={{
                                background: "#f5f5f5",
                                overflowY: "scroll !important"
                            }}>
                                <DialogTrigger>
                                    <div onClick={() => {
                                        this.setState({
                                            searchBarVisible: false
                                        })
                                    }} className='final-click bg-white text-black items-center px-[13px] py-[6px] rounded-[4px] flex flex-column justify-center cursor-pointer preferences-menu-btn h-8 text-lg mt-8'>
                                        <img src='/filters.png' alt="" style={{
                                            display: 'inline-block',
                                            height: "15px",
                                            marginRight: '5px',
                                            marginTop: '3.8px'
    
                                        }} />{item.name}
                                    </div>
    
                                </DialogTrigger>
                                <DialogContent className="w-[100%] min-w-[60vw] lg:max-w-screen-lg overflow-y-scroll h-[93%] md:h-[85vh] max-h-screen" style={{
                                    background: "#f5f5f5",
                                    overflowY: "scroll !important",
                                    // height: '85vh',
                                    marginTop: "45px",
                                    zIndex: 999
                                }}>
                                    <DialogHeader>
                                        {/* <DialogTitle></DialogTitle> */}
                                        <DialogDescription className='mt-4' >
                                            <PreferencesForm onSubmit={() => this.LoginFunc()} />
                                        </DialogDescription>
                                    </DialogHeader>
                                </DialogContent>
                            </Dialog>
                        </div>
                    case 'dropdown':
                        return <React.Fragment key={index}>
                            <div className="p-4">
                                <p onClick={() => this.toggleSubMenu(index)} className="flex items-center justify-between text-lg">{item.name} <ChevronRight/></p>
                            </div>
                            {typeof item.items !== typeof undefined &&
                                <div id={`sub-menu-${index}`} className='flex flex-col w-72 fixed h-screen overflow-y-scroll bg-white text-black text-lg top-0 translate-x-full transition-transform duration-500 z-20'>
                                    <div className="flex justify-between items-center p-4">
                                        <div className="w-1/2" onClick={() => this.toggleSubMenu(index)}><ChevronLeft /></div>
                                        <p className='w-full text-[#0e4621] font-bold'>{item.name}</p>
                                        <p><X onClick={() => {
                                            this.toggleSubMenu(index);
                                            this.toggleMenu();
                                        }}/></p>
                                    </div>
                                    {item.items.map((itemInside, indexInside) => {
                                        return <div className="p-4 border-b-2 py-6" key={`menu-item-${indexInside}`}>
                                                <Link
                                                    to={`${this.props.currentUserType}/recipes/${item.id}/${itemInside.id}`}
                                                    className='final-click'
                                                    dataid={index}>{itemInside.label}</Link>
                                            </div>
                                    })}
                                </div>
                            }
                        </React.Fragment>
                    default:
                        return <React.Fragment key={index}></React.Fragment>;
                }
            }
        })
    }

    isValidWebsite(url) {
        const trimmedUrl = url.trim();

        const allowedDomains = [
            "google\\.com",
            "www\\.marthastewart\\.com",
            "www\\.foodnetwork\\.com",
            "barefootcontessa\\.com",
            "lechefswife\\.com"
        ];

        const regex = new RegExp(
            `^(https?:\\/\\/)(www\\.)?(${allowedDomains.join("|")})(\\/.*)?$`, "i"
        );

        return regex.test(trimmedUrl);
    }
    getProfileMenuHtml(currentUser, currentUserType, name, handle, profileImageUrl) {
        console.log(this.props.currentUser, "dguiuo");
        const userMenuVisible = this.state.userMenuVisible ? 'visible' : undefined;

        const creatorOptionSelected = currentUserType === 'creator' ? 'selected' : undefined;
        const brandOptionSelected = currentUserType === 'brand' ? 'selected' : undefined;
        const consumerOptionSelected = currentUserType === 'consumer' ? 'selected' : undefined;

        const consumerMenuItems = currentUser.roles.includes('consumer') && consumerOptionSelected ?
            (
                <>
                    {/* {config.ENABLE_MY_PROFILE && !this.props.currentUser.guest ? <li id='manage-profile' onClick={() => this.handleManageProfile(currentUserType)}><FontAwesomeIcon icon={faUser} style={{ width: "24px", marginRight: "8px", color: "grey" }} />My Profile</li> : <></>} */}
                    {/* {<li id='manage-devices' className="final-click" onClick={() => this.handleManageDevices(currentUserType)}><FontAwesomeIcon icon={faUtensils} style={{ width: "24px", marginRight: "8px", color: "grey" }} />My Kitchen</li>} */}
                    {(config.ENABLE_MY_KITCHEN || config.ENABLE_MY_PROFILE) ? <div style={{ marginLeft: '12px', marginRight: '12px', height: '1px', borderTop: '1px solid #ccc' }}></div> : <></>}
                    <li className={`final-click flex items-center p-4 text-lg`} onClick={this.handleFeedbackButtonClick}>
                        <FontAwesomeIcon className='mr-2 text-yellow-300' icon={faComments} />
                        Feedback
                    </li>
                        
                </>
            ) : <></>;

        const adminMenuItems = currentUser.roles.includes('admin') ?
            (
                <>
                    <li id='consumer' className={consumerOptionSelected} onClick={() => this.handleUserMenuOptionClick('consumer')}><FontAwesomeIcon icon={faPerson} className='user-menu-icon' />Consumer</li>
                    <li id='brand' className={brandOptionSelected} onClick={() => this.handleUserMenuOptionClick('brand')}><FontAwesomeIcon icon={faBriefcase} className='user-menu-icon' />Brand</li>
                    <li id='creator' className={creatorOptionSelected} onClick={() => this.handleUserMenuOptionClick('creator')}><FontAwesomeIcon icon={faMobileScreen} className='user-menu-icon' />Creator</li>
                    <div style={{ marginLeft: '12px', marginRight: '12px', height: '1px', borderTop: '1px solid #ccc' }}></div>
                </>
            ) : <></>;


        const userMenu = (
            <ul className={userMenuVisible}>
                {consumerMenuItems}
                {adminMenuItems}
                <li id='logout' onClick={() => this.handleLogoutClick()}><FontAwesomeIcon icon={faRightFromBracket} style={{ width: "24px", marginRight: "8px", color: "#cc0000" }} />Logout</li>
            </ul>
        );

        const profileImageStyle = (consumerOptionSelected || creatorOptionSelected) ? 'user-profile-image' : 'brand-profile-image';
            
        return (
            <div>
                <div className="lg:mt-0 mt-2 user-menu cursor-pointer hidden lg:block" ref={this.userMenuRef}>
                    <div className={clsx(["flex p-3 rounded-full items-center lg:flex-row-reverse", {
                        "user-menu-button": !this.state.isMobile
                    }])} onClick={this.handleUserMenuButtonClick}>
                        <img className={`${profileImageStyle}`} src={profileImageUrl} alt="My Profile" />
                        {userMenu}
                        <div className="user-info">
                            <div className="user-name text-[12pt] text-white text-left lg:text-right">{name}</div>
                            <div className="user-handle text-white lg:hidden xl:block">{handle}</div>
                        </div>
                    </div>
                </div>
                <div className="lg:mt-0 mt-2 user-menu cursor-pointer lg:hidden">
                    <div className={clsx(["flex p-3 rounded-full items-center lg:flex-row-reverse", {
                        "user-menu-button": !this.state.isMobile
                    }])}>
                        <img className={`${profileImageStyle}`} src={profileImageUrl} alt="My Profile" />
                        {userMenu}
                        <div className="user-info">
                            <div className="user-name text-[12pt] text-white text-left lg:text-right">{name}</div>
                            <div className="user-handle text-white lg:hidden xl:block">{handle}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    handleLeftSideFixedMobileMenuClick = (e) => {
        if (e.target.classList.contains('final-click') || e.target.parentNode.classList.contains('final-click')) {
            this.toggleMenu();
            if(e.target.getAttribute('dataid')) {
                this.toggleSubMenu(e.target.getAttribute('dataid'));
            }
        }
    }

    //

    render() {
        
        const { currentUserType, currentUser, currentTenant, currentCreatorId } = this.props;

        //console.log({msg:'render', currentUser, currentTenant, currentUserType});

        const currentCreator = CreatorData[currentCreatorId];
        const currentCreatorName = currentCreator.name;
        const currentCreatorHandle = `@${currentCreator.handle}`;
        const currentCreatorImageUrl = `/user-logo-zoe.png`;

        const currentBrandName = currentTenant.name || "unknown";
        const currentBrandHandle = `@${currentTenant.brandData.handle}`;
        const currentBrandImageUrl = `/brands/${currentTenant.id}.png`;

        const currentUserName = currentUser ? currentUser.name : "Unknown";
        const currentUserHandle = currentUser.guest ? "" : currentUser?.email ? currentUser.email.toLowerCase() : "Unknown";
        const currentUserImageUrl = currentUser?.profileImage ? `/users/${currentUser?.profileImage}` : '/users/user-logo.png';

        //

        const menuItemsHtml = this.state.userMenuItems;
        const mobileMenuItemsHtml = this.state.mobileMenu;

        const addContentButtonHtml = currentUserType === 'creator' ? (<Link to={`/${currentUserType}/recipes/create`}><button className="gold" style={{ marginRight: '52px' }}>+ Add Content</button></Link>) : <></>;

        const profileHtmlObj = {
            creator: this.getProfileMenuHtml(currentUser, currentUserType, currentCreatorName, currentCreatorHandle, currentCreatorImageUrl),
            brand: this.getProfileMenuHtml(currentUser, currentUserType, currentBrandName, currentBrandHandle, currentBrandImageUrl),
            consumer: this.getProfileMenuHtml(currentUser, currentUserType, currentUserName, currentUserHandle, currentUserImageUrl),
        };

        const profileHtml = profileHtmlObj[currentUserType];

        const searchBarHtml = this.state.searchBarVisible ?
            (
                <div ref={this.searchBarRef} className={`absolute w-full left-0 top-full bg-gray-100 search-bar-container p-3`}>
                    {
                        this.state.showError ? <div className='text-center text-xl font-bold mt-3 text-red-700'>URL is invalid</div> : <></>
                    }
                    <form className='search-bar' onSubmit={(e) => {
                        e.preventDefault()
                    }}>
                        <span className="hidden lg:block" style={{
                            position: "absolute",
                            right: 10,
                            top: 10,
                            cursor: "pointer",
                            zIndex: 1,
                        }}
                            onClick={() => {
                                const addMarginTopAndAnimationClass = document.getElementById("mainId");
                                this.setState(
                                    {
                                        searchBarVisible: false,
                                    },
                                    () => {
                                        if (this.state.isMobile) {
                                            addMarginTopAndAnimationClass.style.marginTop = "5rem";
                                        } else {
                                            addMarginTopAndAnimationClass.style.marginTop = "7rem";
                                        }
                                    }
                                );
                            }}
                        >
                            <FontAwesomeIcon className='menuIcon' icon={faClose} />
                        </span>
                        <div className="input-container w-full flex justify-center">
                            {this.state.loading ? <div className="loading-icon">
                                <img alt="" src='/loading.svg' />
                            </div> :
                                <div className='flex lg:flex-row justify-center items-center w-full lg:w-auto'>
                                    <div className='relative w-full'>
                                        <textarea
                                            id='textArea'
                                            style={{
                                                height: "50px",
                                                width: "650px",
                                                wordBreak: "break-word",
                                                resize: 'none'
                                            }} autoFocus type="text" className='hidden lg:block px-5' placeholder="Search anything, Search for specific ingredients, Enter a recipe URL." onKeyUp={(e) => {
                                                if (e.key == 'Enter') {
                                                    e.preventDefault();
                                                    this.handleSearchOnSubmit(e)
                                                }else {
                                                    this.setState({
                                                        searchQuery: e.target.value
                                                    })
                                                }
                                            }}
                                        />
                                       <div className="lg:hidden relative">
  <FontAwesomeIcon
    className="absolute left-4 top-6 transform -translate-y-1/2 text-black text-md"
    icon={faSearch}
  />
  <textarea
    id="textArea"
    style={{
      border: "1px solid #ccc",
      borderRadius: "100px",
      outline: "none",
      padding: "10px 12px 10px 40px", // Adjusted padding for icon
      fontSize: "14px",
      lineHeight: "20px",
      boxSizing: "border-box",
      overflow: "hidden",
      height: "43px",
      width: "100%",
      whiteSpace: "nowrap",
      resize: "none",
      color: "#333",
      backgroundColor: "#fff",

    }}
    autoFocus
    className="px-2 text-xs lg:text-lg w-full custom-placeholder shadow-2xl focus:shadow-2xl focus:outline-none"
    placeholder="Search anything"
    value={this.state.searchQuery}
    onKeyUp={(e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        this.handleSearchOnSubmit(e);
      } else {
        this.setState({
          searchQuery: e.target.value,
        });
      }
    }}
    onChange={(e) => this.setState({ searchQuery: e.target.value })}
  />
</div>

                                    </div>
                                    <div className='hidden lg:block'>
                                        <button disabled={this.state.searchQuery === ""} onClick={() => this.handleSearchOnSubmit(this.state.searchQuery)} className="lg:ml-3 h-10 bg-[#0e4621] hover:bg-[#3e8e41] text-white px-4 rounded">Search</button>
                                    </div>
                                </div>
                            }</div>
                    </form>
                    <div className='hidden lg:block text-center bg-gray-100 px-4'>
                        <span style={{
                            opacity: '0.5'
                        }}>Try these keywords</span>
                        <div className='flex flex-col justify-center gap-2'>
                            <div className='mt-3'>
                                <span className='bg-gray-300 px-2 py-1 text-black rounded-[4px]' onClick={(e) => {
                                    document.getElementById('textArea').innerHTML = "Chicken ramen noodles"
                                    this.setState({
                                        searchQuery: "Chicken ramen noodles"
                                    })
                                }} style={{
                                    cursor: 'pointer'
                                }}>
                                    <img alt="" src='/try-prompt.png' style={{
                                        display: 'inline',
                                        height: "15px",
                                        marginRight: '5px',
                                        marginBottom: "5px"
                                    }} />
                                    Chicken ramen noodles
                                </span>
                                <span className='bg-gray-300 px-2 py-1 ml-4 text-black rounded-[4px]'
                                    onClick={(e) => {
                                        document.getElementById('textArea').innerText = "Raspberry crumble bars"
                                        this.setState({
                                            searchQuery: "Raspberry crumble bars"
                                        })
                                    }}
                                    style={{
                                        cursor: 'pointer'
                                    }}>
                                    <img alt="" src='/try-prompt.png' style={{
                                        display: 'inline',
                                        height: "15px",
                                        marginRight: '5px',
                                        marginBottom: "5px"
                                    }} />
                                    Raspberry crumble bars
                                </span>
                            </div>
                            <div className='mt-3'>
                                <span className='bg-gray-300 px-2 py-1 text-black rounded-[4px]' onClick={(e) => {
                                        document.getElementById('textArea').innerText = "Chicken pot pie"
                                        this.setState({
                                            searchQuery: "Chicken pot pie"
                                        })
                                    }} style={{
                                        cursor: 'pointer'
                                    }}>
                                    <img alt="" src='/try-prompt.png' style={{
                                        display: 'inline',
                                        height: "15px",
                                        marginRight: '5px',
                                        marginBottom: "5px"
                                    }} />
                                    Chicken pot pie
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            ) :
            <></>;

         function setViewportHeight() {
             const vh = window.innerHeight * 0.01;
             document.documentElement.style.setProperty('--vh', `${vh}px`);
           }
       
           setViewportHeight();
       
           window.addEventListener('resize', setViewportHeight);

        const feedbackFormHtml = <>
            <div className={clsx(["fixed inset-0 bg-gray-900 opacity-50 z-10"], {
                "hidden": !this.state.isFeedbackFormVisible
            })}></div>
            <div className={clsx(["feedback-form fixed flex flex-col w-screen md:max-w-screen-sm md:p-4 justify-end transition-transform duration-500 left-1/2 -translate-x-1/2 rounded-xl z-[10]", [this.state.isFeedbackFormVisible ? 'top-1/2 -translate-y-1/2' : '-translate-y-full']])} style={{
            height: `calc(var(--vh, 1vh) * 100)`
            }}>
                <iframe title="feedback-form" src="https://docs.google.com/forms/d/e/1FAIpQLSfRUn5864WlRJDxStwJ0PVnlIoS9Jym4LVZ_pHuq7-uP93nwg/viewform?usp=sf_link" width="100%" height="100%">Loading…</iframe>
                {/* <p className='text-white'>Tell Us What You Think!</p>
                <input type="text" placeholder='Enter your email id' style={{
                    width: "100%"
                }}/>
                <textarea rows={4}></textarea> */}
                <div className='text-end mt-2 absolute right-4 bottom-4 lg:right-8 lg:bottom-8'>
                    {/* <button className='bg-[#ccc] text-black rounded-full px-4 py-2 mr-4' onClick={this.handleFeedbackButtonClick}>Cancel</button> */}
                    <button className='bg-[#0e8880] text-[#fff] rounded-full lg:rounded-sm px-3 lg:px-4 py-1 lg:py-2' onClick={this.handleFeedbackButtonClick}>Close</button>
                </div>
            </div>
        </>

        return (
            <>
                <header className={`${this.state.searchBarVisible ? 'search-active' : null} fixed left-1/2 transform -translate-x-1/2 bg-[#0e4621] px-4 xl:px-12 py-2 z-10`}>
                    <div className={clsx(["mobile-custom-menu flex flex-col cursor-pointer text-2xl fixed right-0 top-0 h-screen w-72 bg-[#0e4621] text-white shadow-lg z-50 transition-transform duration-500 lg:hidden", {
                        "translate-x-full": !this.state.isMobileMenuVisible
                    }])} onClick={this.handleLeftSideFixedMobileMenuClick}>
                        <p className="fixed right-0 top-0 p-4 z-10"><X onClick={this.toggleMenu}/></p>
                        {this.state.isMobile && (
                            <div className="flex items-center lg:ml-auto">
                                {profileHtml}
                            </div>
                        )}
                        {mobileMenuItemsHtml}
                    </div>
                    <div className='flex flex-col lg:flex-row justify-between lg:items-center'>
                        <div className="flex justify-between items-center">
                            <Link to="/">
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <img className="logo" src="/Ladle_app_highres_rounded_square.png" alt="Logo" />
                                </div>
                            </Link>
                            <div className='flex items-center'>
                                <Link className="lg:hidden" onClick={this.handleSearchOnClick} ref={this.mobileSearchBtnRef}>
                                    <FontAwesomeIcon className='mr-2 text-white text-2xl' icon={faSearch} />
                                </Link>
                                <button
                                    className="flex flex-col items-center justify-center w-10 h-8 space-y-2 rounded focus:outline-none lg:hidden"
                                    onClick={this.toggleMenu}
                                    ref={this.customMenuBtnRef}
                                >
                                    <span className="block w-6 h-0.5 bg-white"></span>
                                    <span className="block w-6 h-0.5 bg-white"></span>
                                    <span className="block w-6 h-0.5 bg-white"></span>
                                </button>
                            </div>
                        </div>
                        <div className="hidden lg:flex flex-col lg:flex-row items-center flex-grow justify-center lg:justify-between">
                            <div className="custom-menu flex flex-col lg:flex-row items-center justify-center flex-grow flex-wrap">
                                {menuItemsHtml}
                            </div>
                            {!this.state.isMobile && (
                                <div className="flex items-center justify-end lg:ml-auto">
                                    {profileHtml}
                                </div>
                            )}
                            {config.ENABLE_ADD_RECIPE ? addContentButtonHtml : null}
                        </div>
                        
                    </div>
                    {searchBarHtml}
                </header>
                {feedbackFormHtml}
            </>
        );

    }

}

export default MenuBar;
import React, { Component } from "react";
import LoadingIcons from "react-loading-icons";

import RecipeGrid from "../../components/RecipeGrid";
import RemovableTagPill from "../../components/RemovableTagPill.js";
import RecipeService from "../../services/recipe";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faExclamationTriangle,
  faRefresh,
  faTemperatureEmpty,
} from "@fortawesome/free-solid-svg-icons";

import { capitalizeAllWords } from "../../utils/capitalizeWord.js";
import shuffleArray from "../../utils/shuffleArray.js";
import config from "../../utils/config.js";

//
import "../../css/recipes.css";
import SortDropdown from "../../components/SortDropdown.js";

//

const RECIPES_PER_PAGE = 12;
const TOTAL_RECIPES_LIMIT = 1000;

const defaultRequestOptions = {
  offset: 0,
  hideDetails: true,
  limit: TOTAL_RECIPES_LIMIT,
};

class ConsumerRecipesPage extends Component {
  constructor(props) {
    super(props);

    document.title = `Ladle - Recipes`;

    this.state = {
      recipes: undefined,
      user: this.props.user,
      totalRecipesLength: 0,
      totalpersonalRecipesLength: 0,
      options: defaultRequestOptions,
      swithTab: false,
      isLoading: true,
      error: false,
      recipe_per_page: RECIPES_PER_PAGE,
      creator: undefined,
      quick: "",
    };
  }

  //

  async componentDidMount() {
    this.updateOptions();
  }

  componentDidUpdate(prevProps) {
    console.log({
      msg: "componentDidUpdate",
      props: this.props,
      prevProps,
      recipes: this.state.recipes,
    });
    let categoryUpdated =
      this.props.params?.category &&
      this.props.params?.category !== prevProps?.params?.category;
    let queryParamsUpdated =
      this.props.location?.search !== prevProps.location?.search;
    let locationUpdated =
      this.props.location?.pathname !== prevProps.location?.pathname;
    if (categoryUpdated || queryParamsUpdated || locationUpdated)
      this.updateOptions(defaultRequestOptions);
  }

  async updateOptions(existingOptions) {
    console.log(existingOptions, this.state.options, "values are");
    let { user, params, location } = this.props;
    if (!existingOptions) existingOptions = this.state.options;

    console.log({ msg: "updateOptions", user, params, location });

    const queryParams = new URLSearchParams(location?.search);

    if (this.state.swithTab == true) {
      delete existingOptions.is_personalized;
      delete existingOptions.user_id;
    }
    let options = {
      ...existingOptions,
      sort: "random",
    };

    for (let [key, value] of queryParams.entries()) {
      options[key] = value;
    }

    // convert tags string to an array and clean-up
    if (options.tags && typeof options.tags === "string") {
      options.tags = options.tags
        .toLowerCase()
        .split(",")
        .map((t) => t.trim());
    } else {
      options.tags = [];
    }

    if (params?.category) options.tags = params?.category.toLowerCase();
    if (params?.cuisine) options.cuisine = params?.cuisine.toLowerCase();
    if (params?.diet) options.diet = params?.diet.toLowerCase();
    if (params?.meal) options.tags = [params?.meal.toLowerCase()];
    if (params?.ingredient) options.tags = [params?.ingredient.toLowerCase()];
    if (params?.tag) options.tags = [params?.tag.toLowerCase()];
    this.setState({
      quick: params?.meal,
    });
    if (params?.search) options.search = [params?.search.toLowerCase()];

    options.offset = 0;

    console.log({ msg: "updateOptions", options });

    let totalRecipesLength = await RecipeService.count(options, user);

    if (this.state.swithTab == true) this.getPeronalData(options, user);
    totalRecipesLength =
      totalRecipesLength > 1000 || !config.DEMO_MODE
        ? totalRecipesLength
        : 3518;

    this.setState({
      recipes: undefined,
      error: false,
      isLoading: true,
      totalRecipesLength,
      options,
      category: params?.category,
    });

    this.getRecipes(options);
  }

  async getPeronalData(options, user) {
    options.is_personalized = true;
    options.user_id = user.email;
  }

  async getRecipes(options) {
    if (localStorage.getItem("tags") != null && options.tags?.length == 0) {
      options.tags = [localStorage.getItem("tags")];
      localStorage.removeItem("tags");
    } else if (options.tags?.length == 0) {
      localStorage.removeItem("tags");
    }

    console.log(
      options.tags?.length == 0,
      options.search == undefined,
      options.cuisine == undefined
    );
    console.log(options.cuisine);
    if (
      options.tags?.length == 0 &&
      options.search == undefined &&
      options.cuisine == undefined &&
      options.diet == undefined
    ) {
      localStorage.removeItem("tags");
      this.props.navigate("/");
    }
    options.tags.forEach((tag) => {
      console.log("options.tags", tag);
    });

    console.log({ msg: "getRecipes", options });
    let { user } = this.props;
    let recipes;
    try {
      options.hideDetails = true;
      recipes = await RecipeService.list(options, user);
      console.log("hmm", options.search);
      if (options.search != undefined) {
        recipes = this.sortBasedOnTitleMatch(recipes, options.search[0]);
      }
      if (this.state.quick == "quick") {
        recipes = recipes
          .filter((recipe) => {
            // Make sure totalTime is a valid number or assign Infinity if it's not
            return (
              recipe.times &&
              recipe.times.totalTime != null &&
              recipe.times.totalTime <= 45
            );
          })
          .sort((a, b) => {
            // Convert totalTime to a number for consistent sorting
            const timeA = parseInt(a.times.totalTime, 10) || Infinity;
            const timeB = parseInt(b.times.totalTime, 10) || Infinity;

            return timeB - timeA; // Ascending order: lowest to highest
          });
      }
      this.setState({
        recipes,
        options,
        isLoading: false,
        error: false,
        totalRecipesLength: recipes.length,
      });
    } catch (err) {
      console.log("getRecipes.error");
      console.log(err);
      this.setState({ recipes, options, isLoading: false, error: err.message });
    }
  }

  sortBasedOnTitleMatch(objects, query) {
    const queryWords = query.toLowerCase().split(" ");

    // Function to calculate score for each title
    function calculateScore(title) {
      const normalizedTitle = title.toLowerCase();
      let score = 0;

      // Full match score
      if (normalizedTitle === query.toLowerCase()) {
        score += 100;
      }

      // Partial match scores
      queryWords.forEach((word) => {
        if (normalizedTitle.includes(word)) {
          score += 10;
        }
      });

      return score;
    }

    // Assign scores to each object
    objects.forEach((object) => {
      object.score = calculateScore(object.title);
    });

    // Sort objects based on scores in descending order
    objects.sort((a, b) => b.score - a.score);

    // Optionally, remove the score attribute if not needed
    objects.forEach((object) => {
      delete object.score;
    });

    return objects;
  }

  handlePreviousClick = () => {
    let { options } = this.state;
    let offset = this.state.options.offset - RECIPES_PER_PAGE;
    if (offset < 0) offset = 0;
    options.offset = offset;
    this.setState({ options });
  };

  handleNextClick = () => {
    let { options } = this.state;
    let offset = options.offset + RECIPES_PER_PAGE;
    if (offset < 0) offset = 0;
    options.offset = offset;
    this.setState({ options });
  };

  //

  handleSortOptionClick = async (sortOption) => {
    console.log({ msg: "handleSortOptionClick", sortOption });
    let options = this.state.options;
    options.sortBy = sortOption.id ? sortOption.id : "random";
    if (sortOption.id === "random") {
      options.sort = "random";
      options.order = "desc";
    }
    if (sortOption.id === "newest") {
      options.sort = "updatedat";
      options.order = "desc";
    }
    if (sortOption.id === "oldest") {
      options.sort = "updatedat";
      options.order = "asc";
    }
    if (sortOption.id === "alphabetical") {
      options.sort = "title";
      options.order = "asc";
    }
    const totalRecipesLength = await RecipeService.count(
      options,
      this.props.user
    );
    this.setState({
      slicedRecipes: undefined,
      recipes: undefined,
      totalRecipesLength,
      isLoading: true,
      options,
    });
    this.getRecipes(options);
  };

  //

  handleTagClick = async (tag) => {
    console.log({ msg: "handleTagClick", tag });
    let options = this.state.options;
    options.tags = [tag];
    options.offset = 0;
    const totalRecipesLength = await RecipeService.count(
      options,
      this.props.user
    );
    this.setState({
      slicedRecipes: undefined,
      recipes: undefined,
      totalRecipesLength,
      isLoading: true,
      options,
    });
    this.getRecipes(options);
  };

  handleRemoveTagClick = async (tagToRemove) => {
    let options = this.state.options;
    options.tags = options.tags?.filter((t) => t !== tagToRemove.toLowerCase());
    options.offset = 0;
    let totalRecipesLength = await RecipeService.count(
      options,
      this.props.user
    );
    totalRecipesLength =
      totalRecipesLength > 1000 || !config.DEMO_MODE
        ? totalRecipesLength
        : 3518;
    this.setState({
      recipes: undefined,
      totalRecipesLength,
      isLoading: true,
      options,
    });
    this.getRecipes(options);
    window.history.pushState(
      { path: "/consumer/recipes" },
      "",
      "/consumer/recipes"
    );
  };

  handleRemoveTagFieldClick = async (field) => {
    let options = this.state.options;
    delete options[field];
    options.offset = 0;
    const totalRecipesLength = await RecipeService.count(
      options,
      this.props.user
    );
    this.setState({
      recipes: undefined,
      totalRecipesLength,
      isLoading: true,
      options,
    });
    this.getRecipes(options);
    window.history.pushState(
      { path: "/consumer/recipes" },
      "",
      "/consumer/recipes"
    );
  };

  reload = () => {
    this.setState({ recipes: undefined, error: false, isLoading: true });
    this.getRecipes(this.state.options);
  };

  //

  render() {
    const { recipes, totalRecipesLength, options, isLoading, error } =
      this.state;

    const totalRecipesLengthStr = totalRecipesLength?.toLocaleString("en-us");

    const activeTags = this.state.options?.tags ? this.state.options?.tags : [];
    const activeTitles = this.state.options?.title
      ? [this.state.options?.title]
      : [];
    const activeCuisine = this.state.options?.cuisine
      ? [this.state.options?.cuisine]
      : [];
    const activeDiet = this.state.options?.diet
      ? [this.state.options?.diet]
      : [];
    const activeSearch = this.state.options?.search
      ? [this.state.options?.search]
      : [];

    const activeTagPillsHtml =
      activeTags && activeTags.length > 0 ? (
        activeTags.map((t, index) => (
          <RemovableTagPill
            key={`${t}${index}`}
            label={t}
            color="grey"
            size="large"
            onClick={() => this.handleRemoveTagClick(t)}
          />
        ))
      ) : (
        <></>
      );
    const activeTitlesPillsHtml = activeTitles ? (
      activeTitles.map((t, index) => (
        <RemovableTagPill
          key={`${t}${index}`}
          label={`Title: ${t}`}
          color="fuchsia"
          size="large"
          onClick={() => this.handleRemoveTagFieldClick("title")}
        />
      ))
    ) : (
      <></>
    );
    const activeCuisinePillsHtml = activeCuisine ? (
      activeCuisine.map((t, index) => (
        <RemovableTagPill
          key={`${t}${index}`}
          label={`Cuisine: ${t}`}
          color="burntorange"
          size="large"
          onClick={() => this.handleRemoveTagFieldClick("cuisine")}
        />
      ))
    ) : (
      <></>
    );
    const activeDietPillsHtml = activeDiet ? (
      activeDiet.map((t, index) => (
        <RemovableTagPill
          key={`${t}${index}`}
          label={`Diet: ${t}`}
          color="orange"
          size="large"
          onClick={() => this.handleRemoveTagFieldClick("diet")}
        />
      ))
    ) : (
      <></>
    );
    const activeSearchPillsHtml = activeSearch ? (
      activeSearch.map((t, index) => (
        <RemovableTagPill
          key={`${t}${index}`}
          label={`Search: ${t}`}
          color="blue"
          size="large"
          onClick={() => this.handleRemoveTagFieldClick("search")}
        />
      ))
    ) : (
      <></>
    );

    let loadingText = `Loading Recipes`;
    if (options && options.tag) {
      loadingText = `Loading '${capitalizeAllWords(options.tag)}' Recipes`;
    } else if (options && options.title) {
      loadingText = `Loading '${capitalizeAllWords(options.title)}' Recipes`;
    }

    let contentSectionHtml;
    let recipesToShow = recipes
      ? recipes.slice(
          options.offset,
          options.offset + this.state.recipe_per_page
        )
      : [];

    console.log("render", error, isLoading, recipesToShow);

    if (error) {
      contentSectionHtml = (
        <div
          style={{
            textAlign: "center",
            verticalAlign: "middle",
            alignItems: "center",
            justifyContent: "center",
            height: "400px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p style={{ fontSize: "14pt", marginBottom: "32px" }}>
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              style={{ paddingRight: "12px" }}
            />
            Unable to load recipes. Please check your server connection and try
            again.
          </p>
          <button className="outline" onClick={() => this.reload()}>
            <FontAwesomeIcon
              icon={faRefresh}
              style={{ paddingRight: "12px" }}
            />
            Retry
          </button>
        </div>
      );
    } else if (isLoading) {
      contentSectionHtml = (
        <div
          style={{
            textAlign: "center",
            verticalAlign: "middle",
            alignItems: "center",
            justifyContent: "center",
            height: "400px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p style={{ fontSize: "16pt", marginBottom: "32px" }}>
            {loadingText}
          </p>
          <LoadingIcons.TailSpin
            height="48px"
            stroke="#0e4621"
            fill="#0e4621"
          />
        </div>
      );
    } else if (recipes.length === 0) {
      contentSectionHtml = (
        <div
          style={{
            textAlign: "center",
            verticalAlign: "middle",
            alignItems: "center",
            justifyContent: "center",
            height: "400px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p style={{ fontSize: "14pt", marginBottom: "32px" }}>
            <FontAwesomeIcon
              icon={faTemperatureEmpty}
              style={{ paddingRight: "12px" }}
            />
            No recipes found
          </p>
        </div>
      );
    } else {
      const endOfCurrentRecipeCount = options.offset + recipesToShow.length;
      const prevBtnDisabled = options.offset === 0;
      const nextBtnDisabled = endOfCurrentRecipeCount >= totalRecipesLength;

      contentSectionHtml = (
        <div>
          <RecipeGrid
            recipes={recipesToShow}
            type="consumer"
            user={this.props.user}
            tenant={this.props.tenant}
            handleTagClick={this.handleTagClick}
          />
        </div>
      );
    }

    return (
      <>
        <div className="lg:max-w-[1250px]">
          <div className="content-menu-bar">
            {activeTagPillsHtml}
            {activeTitlesPillsHtml}
            {activeCuisinePillsHtml}
            {activeDietPillsHtml}
            {activeSearchPillsHtml}
          </div>
          <h1 className="text-2xl mb-3 font-bold text-[#0e4621]">
            {" "}
            Filtered Recipes{" "}
          </h1>

          <div className="tile">{contentSectionHtml}</div>
          <div className="flex justify-center mt-2">
            <button
              onClick={() => {
                this.setState({
                  recipe_per_page: this.state.recipe_per_page + 8,
                });
              }}
              className="text-center green"
            >
              Load More
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default ConsumerRecipesPage;